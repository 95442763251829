<template>
    <v-dialog v-model="visible" max-width="500px" persistent>
        <v-card>
        <v-toolbar color="primary" dark> Maintenance Employee </v-toolbar>
        <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            bottom
            color="deep-purple accent-4"
        ></v-progress-linear>

        <v-card-text>
            <v-container fluid>
            <v-row>
                <v-col cols="8">
                    <v-text-field
                        v-model="$store.state.masterMaintenanceEmployee.data.nik"
                        counter="50"
                        hint="Max 50 char"
                        :rules="[rules.required, rules.max50]"
                        label="NIK"
                        :disabled="disabled"
                    ></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-combobox
                        v-model="$store.state.masterMaintenanceEmployee.data.category"
                        :items="categories"
                        label="Employee Category"
                        outlined
                    >
                    </v-combobox>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        v-model="$store.state.masterMaintenanceEmployee.data.nama"
                        counter="255"
                        hint="Max 255 char"
                        :rules="[rules.required, rules.max]"
                        label="Employee Name"
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-switch
                        v-model="$store.state.masterMaintenanceEmployee.data.isActive"
                        :label="`Active: ${$store.state.masterMaintenanceEmployee.data.isActive}`"
                    ></v-switch>
                </v-col>
            </v-row>
            </v-container>
            <!-- <small>*indicates required field</small> -->
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="close"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="save"> {{btSave}} </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

export default {
    props: {
        visible:Boolean,
        btSave:"",
        disabled:Boolean,
    },
    data() {
        return{
            loading:false,
            isSuccess:false,
            rules: {
                required: value => !!value || 'Required.',
                max50: v => v.length <= 50 || 'Max 255 characters',
                max: v => v.length <= 255 || 'Max 255 characters',
            },
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false,
            },
            dataFilter:{
                nik:"",
                category:"",
                nama:"",
                is_active:"",
                page:1,
                item_per_page:10,
            },
            categories:['PRD','MTC']
        }
    },
    mounted(){
    },
    methods:{
        clearVar(){
            this.$store.state.masterMaintenanceEmployee.data.nik= "";
            this.$store.state.masterMaintenanceEmployee.data.category= "";
            this.$store.state.masterMaintenanceEmployee.data.nama = "";
            this.$store.state.masterMaintenanceEmployee.data.isActive=true;
        },
        close(){
            this.clearVar();
            this.$store.state.masterMaintenanceEmployee.dialogVisible = false;
        },

        async save(){
            console.log("save");
            var id = this.$store.state.masterMaintenanceEmployee.data.nik;
            var category = this.$store.state.masterMaintenanceEmployee.data.category;
            var name = this.$store.state.masterMaintenanceEmployee.data.nama;
            var activeFlag = this.$store.state.masterMaintenanceEmployee.data.isActive;
            var data = this.dataFilter;
            data.page = this.$store.state.masterMaintenanceEmployee.page,
            data.item_per_page = this.$store.state.masterMaintenanceEmployee.itemPerPage,
            console.log("name = " + name);
            console.log("activeFlag = " + activeFlag);
            if (id!== '' || name !== '') {
                this.loading=true;
                if(this.btSave == "Save"){
                    console.log("save");
                    await axios.post(`${process.env.VUE_APP_URL}/api/master/kka/master_employee`, {
                        'nik': id,
                        'category': category,
                        'nama': name,
                        'is_active': activeFlag,
                    }, { 
                        headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                    }).then(res => {
                        this.loading = false;
                        this.isSuccess = true;
                        this.$store.state.masterMaintenanceEmployee.isProgress = true;
                        
                        this.$store.dispatch("getMasterMaintenanceEmployee", { data }).then(() => {
                                this.snackbar = {
                                color: "success",
                                icon: "mdi-checkbox-marked-circle",
                                mode: "multi-line",
                                position: "top",
                                timeout: 7500,
                                title: "Success",
                                text: "Successfully Insert Maintenance Employee",
                                visible: true
                            };
                            // this.visible = false;
                            this.$emit('is-saved', { snackbar: this.snackbar});
                            this.isSuccess = false;
                            this.$store.state.masterMaintenanceEmployee.isProgress = false;
                            this.close();
                        })
                        .catch((err) => {
                            //   console.log("errror ");
                            //   console.log(err);
                            //   console.log(err.response);
                            //   console.log(err.response.data);
                            //   console.log(err.response.status);
                            this.$store.state.masterMaintenanceEmployee.isProgress = false;
                            this.snackbar = {
                                color: "error",
                                icon: "mdi-alert-circle",
                                mode: "multi-line",
                                position: "top",
                                timeout: 7500,
                                title: "Error",
                                text: err.response.status + " " + err.response.statusText,
                                visible: true
                            };
                        });

                        
                    }).catch(err => {
                        this.loading = false
                        this.isSuccess = false;
                        this.snackbar = {
                            color: "error",
                            icon: "mdi-alert-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Error",
                            text: err.response.data.message,
                            visible: true
                        };
                        this.$emit('is-saved', { snackbar: this.snackbar })
                    })
                } else {
                    console.log("update");
                    await axios.put(`${process.env.VUE_APP_URL}/api/master/kka/master_employee/update`, {
                        'nik': id,
                        'category': category,
                        'nama': name,
                        'is_active': activeFlag,
                    }, { 
                        headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                    }).then(res => {
                        console.log("update berhasil");
                        this.loading = false;
                        this.isSuccess = true;
                        this.$store.state.masterMaintenanceEmployee.isProgress = true;
                        // var data = this.dataFilter;
                        this.$store.dispatch("getMasterMaintenanceEmployee", { data }).then(() => {
                                this.snackbar = {
                                color: "success",
                                icon: "mdi-checkbox-marked-circle",
                                mode: "multi-line",
                                position: "top",
                                timeout: 7500,
                                title: "Success",
                                text: "Successfully Update Maintenance Employee",
                                visible: true
                            };
                            // this.visible = false;
                            this.$emit('is-saved', { snackbar: this.snackbar});
                            this.isSuccess = false;
                            this.$store.state.masterMaintenanceEmployee.isProgress = false;
                            this.close();
                        })
                        .catch((err) => {
                            this.loading = false;
                            this.isSuccess = false;
                            //   console.log("errror ");
                            //   console.log(err);
                            //   console.log(err.response);
                            //   console.log(err.response.data);
                            //   console.log(err.response.status);
                            this.$store.state.masterMaintenanceEmployee.isProgress = false;
                            this.snackbar = {
                                color: "error",
                                icon: "mdi-alert-circle",
                                mode: "multi-line",
                                position: "top",
                                timeout: 7500,
                                title: "Error",
                                text: err.response.status + " " + err.response.statusText,
                                visible: true
                            };
                            this.$emit('is-saved', {snackbar: this.snackbar});
                        });

                    }).catch(err => {
                        this.loading = false
                        this.isSuccess = false;
                        this.snackbar = {
                            color: "error",
                            icon: "mdi-alert-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Error",
                            text: err.response.data.message,
                            visible: true
                        };
                        this.$emit('is-saved', { snackbar: this.snackbar })
                    })
                }
                
            }
        }
    },
}
</script>
